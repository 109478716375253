import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import { getRandomInt, getQueryFields } from '../../utils/helpers'
import ReadMoreableFeatureArticle from './ReadMoreableFeatureArticle'

const HomeReadMore = ({ reverse, featureCaption = 'Feature' }) => (
  <StaticQuery
    query={graphql`
          query ($category: String="Home") {
            ...CompleteArticleInCategory
          }
        `}
    render={data => {
      const homeArticles = getQueryFields(data.allMarkdownRemark.nodes)
      const article = homeArticles[getRandomInt(homeArticles.length)]
      return (
        <ReadMoreableFeatureArticle
          article={article}
          reverse={reverse}
          featureCaption={featureCaption}
        />
      )
    }}
  />
)

export default HomeReadMore
