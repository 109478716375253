import React from 'react'
import Img from 'gatsby-image'
import PreloadBannerHelmet from '../../helmet/PreloadBannerHelmet'
import {
  FeaturedArticleBanner as ArticleBanner,
  CategoryHighlight,
  FeaturedArticleCopy,
  FeaturedArticleTitle
} from '../../../styles'

const FeaturedArticleBanner = ({ article }) => {
  const articleTitle = article?.title
    ? article.title.trim().split(' ')
    : '10 Essential Tips to Forward this Fall'.split(' ')
  return (
    <>
      {article && (
        <PreloadBannerHelmet
          src={article.banner.childImageSharp.fluid.src}
          srcSet={article.banner.childImageSharp.fluid.srcSet}
          sizes={article.banner.childImageSharp.fluid.sizes}
        />)}
      <ArticleBanner to={article?.slug}>
        <FeaturedArticleCopy>
          <CategoryHighlight>Feature</CategoryHighlight>
          <FeaturedArticleTitle aria-label={articleTitle.join(' ')}>
            {articleTitle.map((lineWord, idx) =>
              <span key={idx}>{lineWord}</span>
            )}
          </FeaturedArticleTitle>
        </FeaturedArticleCopy>
        {
          (article && article?.banner) &&
            <Img
              fluid={article.banner.childImageSharp.fluid}
              fadeIn={false}
              loading='eager'
              objectFit='cover'
              objectPosition='50% 50%'
            />
        }
      </ArticleBanner>
    </>
  )
}

export default FeaturedArticleBanner
