import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { below } from '../../utils/media-query'
import { previewHoverEffect } from '../../styles'

const FeaturedTechnologyContainer = styled.div`
  width: 50%;
  padding-right: 15px;
  ${below.med`
    padding-right: 8px;
  `}
  ${below.small`
    padding: 0;
    width: 100%;
  `}
`
const Title = styled.p`
  font-family: "Josefin Sans", Helvetica, sans-serif;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #181a1b;
  ${below.med`
    font-size: 26px;
  `}
  ${below.small`
    font-size: 20px;
    margin-bottom: 12px;
  `}
`
const ArticleTitle = styled.h2`
  margin-top: 12px !important;
  display: inline-block;
  font-family: "Josefin Sans", Helvetica, sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal !important;
  color: #181a1b !important;
  ${below.med`
    font-size: 24px;
  `}
  ${below.small`
    font-size: 16px;
    margin-bottom: 12px;
  `}
`
const ArticlePreview = styled(Link)`
  ${previewHoverEffect}
`
const ArticlePreviewImage = styled(Img)`
  height: 34.125rem;
  ${below.med`
    height: 471px;
  `}
  ${below.small`
    height: 338px;
  `}
`

const FeaturedTechnology = ({ article }) => (
  <FeaturedTechnologyContainer>
    <Title>Featured Technology</Title>
    <ArticlePreview to={article?.slug}>
      {article?.largePreviewImage &&
        <ArticlePreviewImage
          fluid={article.largePreviewImage.childImageSharp.fluid}
        />}
      <ArticleTitle>{article?.title}</ArticleTitle>
    </ArticlePreview>
  </FeaturedTechnologyContainer>
)

export default FeaturedTechnology
