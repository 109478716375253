import React from 'react'
import { Helmet } from 'react-helmet'

const PreloadBannerHelmet = ({ src, srcSet, sizes }) => {
  return (
    <Helmet>
      <link
        rel='preload'
        as='image'
        href={src}
        imagesrcset={srcSet}
        imagesizes={sizes}
      />
    </Helmet>
  )
}

export default PreloadBannerHelmet
