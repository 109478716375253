import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import { getRandomInt, getQueryFields } from '../../utils/helpers'
import ReadMoreableFeatureArticle from './ReadMoreableFeatureArticle'

const EducationReadMore = ({ reverse, featureCaption = 'Feature' }) => (
  <StaticQuery
    query={graphql`
          query ($category: String="Education") {
          ...CompleteArticleInCategory
          }
      `}
    render={data => {
      const educationArticles = getQueryFields(data.allMarkdownRemark.nodes)
      const article = educationArticles[getRandomInt(educationArticles.length)]
      return (
        <ReadMoreableFeatureArticle
          article={article}
          reverse={reverse}
          featureCaption={featureCaption}
        />
      )
    }}
  />
)

export default EducationReadMore
