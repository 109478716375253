import React, { useState, useEffect } from 'react'
import {
  TwoColourBackgroundContainer,
  TwoColourBackgoundPanel
} from '../../styles'
import Post from '../postPage/Post'

const ReadMoreableFeatureArticle = ({ article, reverse, featureCaption }) => {
  const [expanded, setExpanded] = useState(false)
  const [completeArticle, setCompleteArticle] = useState(null)
  const featureTagColor = `${reverse ? '#704452' : '#0ccaff'}`
  useEffect(() => {
    setCompleteArticle({
      ...article,
      featureCaption,
      setExpanded,
      featureTagColor
    })
  }, [])
  return completeArticle
    ? (
      <TwoColourBackgoundPanel reverse={reverse} expanded={expanded}>
        <TwoColourBackgroundContainer reverse={reverse} expanded={expanded}>
          {expanded
            ? <Post extendable extended article={completeArticle} />
            : <Post
                extendable
                extended={false}
                reverse={reverse}
                article={completeArticle}
              />}
        </TwoColourBackgroundContainer>
      </TwoColourBackgoundPanel>
      )
    : null
}

export default ReadMoreableFeatureArticle
