import React, { Component } from 'react'

import Articles from './Articles'

export default class HomePage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      posts: null,
      latestInAuto: null,
      simpleHomeArticles: null,
      simpleEducationArticles: null
    }
  }

  componentDidMount () {
    const { data } = this.props
    const mostArticles = data.mostArticles.nodes
    const latestInAuto = data.latestInAuto.nodes
    const simpleHomeArticles = data.simpleHomeArticles.nodes
    const simpleEducationArticles = data.simpleEducationArticles.nodes

    this.setState({
      posts: [
        ...mostArticles,
        ...simpleHomeArticles,
        ...simpleEducationArticles,
        ...latestInAuto
      ],
      latestInAuto,
      simpleHomeArticles,
      simpleEducationArticles
    })
  }

  render () {
    const { location } = this.props
    return this.state.posts &&
      <Articles posts={this.state.posts} location={location} />
  }
}
