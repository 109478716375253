import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import { below } from '../../utils/media-query'
import { previewHoverEffect } from '../../styles'

const ArticleList = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  ${below.med`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  `}
  ${below.small`
    flex-wrap: wrap;
    padding: 0;
    margin: 0 auto;
    max-width: 288px;
  `}
`

const ArticleListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 75.375rem;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  margin-bottom: 3rem;
`

const ArticleListTitle = styled.p`
  font-family: 'Abril Fatface', cursive;
  font-size: 52px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #181a1b;
  margin-top: 30px;
  ${below.med`
    font-size: 32px;
  `}
  ${below.small`
    font-size: 26px;
    margin-top: 24px;
    margin-bottom: 16px;
  `}
`

const ArticlePreview = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 16.6875rem;
  height: auto;
  margin: 0 0 0 14px;
  ${previewHoverEffect}
  &:first-child {
    margin: 0;
  }
  ${below.med`
    margin-left: 10px;
  `}
  ${below.small`
  min-width: 140px;
  width: 140px;
  height: auto;
  margin 0;
  &:nth-child(2n) {
    margin: 0 0 0 8px;
    }
  &:nth-child(-n + 2) {
  margin-bottom: 12px;
  }
  `}
`
const ArticleImage = styled(Img)`
  width: 100%;
  height: 267px;
  ${below.med`
    min-width: 172px;
    width: 172px;
    height: 172px;
  `}
  ${below.small`
    min-width: 140px;
    width: 140px;
    height: 140px;
  `}
`

const ArticleTitle = styled.h2`
  margin: 12px 0 0;
  font-family: 'Josefin Sans', Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #181a1b;
  ${below.med`
    font-size: 16px;
  `}
  ${below.small`
    font-size: 12px;
  `}
`

const HorizontalFeaturedArticleList = ({ articles, title }) => (
  <ArticleListContainer>
    <ArticleListTitle>{title}</ArticleListTitle>
    <ArticleList>
      {articles.map((article, idx) => (
        <ArticlePreview key={idx} to={article?.slug}>
          {article?.previewImage &&
            <ArticleImage
              fluid={article.previewImage.childImageSharp.fluid}
            />}
          <ArticleTitle>{article.title}</ArticleTitle>
        </ArticlePreview>
      ))}
    </ArticleList>
  </ArticleListContainer>
)

export default HorizontalFeaturedArticleList
