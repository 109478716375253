import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import { below } from '../../utils/media-query'
import { previewHoverEffect } from '../../styles'

const Title = styled.p`
  font-family: "Josefin Sans", Helvetica, sans-serif;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: #181a1b;
  ${below.med`
    font-size: 26px;
  `}
  ${below.small`
    font-size: 20px;
    margin-bottom: 12px;
  `}
`

const TrendingTopicsContainer = styled.div`
  width: 50%;
  padding-left: 15px;
  ${below.med`
    padding-left: 8px;
  `}
  ${below.small`
    padding: 0;
    padding-top: 21px;
    width: 100%;
  `}
`

const VerticalArticlesList = styled.div`
  display: flex;
  flex-direction: column;
`

const ArticlePreview = styled(Link)`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  ${previewHoverEffect}
  ${below.med`
    margin-bottom: 15px;
  `}
  ${below.small`
    margin-bottom: 8px;
  `}
`

const ArticlePreviewImage = styled(Img)`
  width: 35%;
  min-width: 35%;
  height: ${33.75 * 0.35}rem;
  ${below.med`
    width: 172px;
    min-width: 172px;
    height: 172px;
  `}
  ${below.small`
    width: 35%;
    min-width: 35%;
    height: ${18 * 0.35}rem;
    padding: 0;
  `}
`

const ArticlePreviewTitle = styled.h2`
  padding: 15px;
  margin-right: 2.5rem !important;
  font-family: "Josefin Sans", Helvetica, sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #181a1b;
  ${below.med`
    font-size: 16px;
    margin-right: 0 !important;
    padding-right: 0;
  `}
  ${below.small`
    padding-right: 15px;
    margin-right: 0.5rem !important;
    font-size: 14px;
  `}
`

const TrendingTopics = ({ articles, title }) => (
  <TrendingTopicsContainer>
    <Title>{title}</Title>
    <VerticalArticlesList>
      {articles.map((article, idx) => (
        <ArticlePreview to={article?.slug} key={idx}>
          {article?.smallPreviewImage &&
            <ArticlePreviewImage
              fluid={article.smallPreviewImage.childImageSharp.fluid}
            />}
          <ArticlePreviewTitle>{article.title}</ArticlePreviewTitle>
        </ArticlePreview>
      ))}
    </VerticalArticlesList>
  </TrendingTopicsContainer>
)

export default TrendingTopics
