import React from 'react'
import HorizontalFeaturedArticleList from './homepage/HorizonalFeaturedArticleList'
import FeaturedTechnology from './homepage/FeaturedTechnology'
import TrendingTopics from './homepage/TrendingTopics'
import HomeReadMore from './homepage/homeReadMore'
import EducationReadMore from './homepage/educationReadMore'
import {
  FullWidthFeatureArticle,
  FullWidthFeatureArticleCopy,
  FullWidthFeatureArticleTitle,
  ColoredCategoryHighlight,
  TwoColumnPanel
} from '../styles'
import Layout from './Layout'
import { getQueryFields, getRandom, getRandomInt, maxValueOrNumber, sortLatest } from '../utils/helpers'
import Img from 'gatsby-image'
import FeaturedArticleBanner from './common/page_components/FeaturedArticleBanner'

const categories = {
  HEALTH: 'Health & Wellness',
  TECH: 'Tech',
  AUTO: 'Auto',
  HOME: 'Home',
  EDUCATION: 'Education'
}

const Articles = ({ posts, location }) => {
  const articlesMapped = getQueryFields(posts, location)

  const filterByCategory = (category) => (x) => x.category === categories[category]
  const healthArticles = articlesMapped.filter(filterByCategory('HEALTH'))
  const autoArticles = articlesMapped.filter(filterByCategory('AUTO'))
  const techArticles = articlesMapped.filter(filterByCategory('TECH'))

  const randomArticle = articlesMapped[getRandomInt(articlesMapped.length)]

  const latestAutoArticle = autoArticles.sort(sortLatest)[0]

  return (
    <Layout removeFooter adsense={false}>
      <FeaturedArticleBanner article={randomArticle} />
      <HorizontalFeaturedArticleList
        articles={getRandom(healthArticles, maxValueOrNumber(healthArticles, 4))}
        title='Everyday Health & Wealth'
      />
      <FullWidthFeatureArticle>
        <FullWidthFeatureArticleCopy>
          <ColoredCategoryHighlight>Feature</ColoredCategoryHighlight>
          <FullWidthFeatureArticleTitle to={latestAutoArticle?.slug}>
            {latestAutoArticle?.title}
          </FullWidthFeatureArticleTitle>
        </FullWidthFeatureArticleCopy>
        {
          latestAutoArticle &&
            <Img
              fluid={latestAutoArticle.banner.childImageSharp.fluid}
              objectFit='cover'
              objectPosition='50% 50%'
            />
        }
      </FullWidthFeatureArticle>
      <TwoColumnPanel>
        <FeaturedTechnology
          article={techArticles.sort(sortLatest)[0] || articlesMapped[17]}
        />
        <TrendingTopics
          articles={getRandom(articlesMapped, maxValueOrNumber(articlesMapped, 3))}
          title='Trending Topics'
        />
      </TwoColumnPanel>
      <EducationReadMore />
      <HorizontalFeaturedArticleList
        articles={getRandom(articlesMapped, maxValueOrNumber(articlesMapped, 4))}
        title='This Months Hot Reads'
      />
      <HomeReadMore reverse />
    </Layout>
  )
}

export default Articles
